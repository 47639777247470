import { useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  AltNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const location = useLocation(); // Get current location

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const isHomePage = location.pathname === "/"; // Check if current path is homepage

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

    if (isHomePage) {
      return (
        <>
          <CustomNavLinkSmall onClick={() => scrollTo("about")}>
            <Span>{t("About")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => scrollTo("integration")}>
            <Span>{t("Integration")}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => scrollTo("demo")}>
            <Span>{t("Demo")}</Span>
          </CustomNavLinkSmall>
          <AltNavLinkSmall style={{ width: "180px" }} to="/contact-us">
            <Span>
              <Button>{t("Contact Us")}</Button>
            </Span>
          </AltNavLinkSmall>
        </>
      );
    } else {
      // Render different links for non-homepage
      return (
        <>
          <AltNavLinkSmall to="/">
            <Span>{t("Home")}</Span>
          </AltNavLinkSmall>
          <AltNavLinkSmall style={{ width: "180px" }} to="/contact-us">
            <Span>
              <Button>{t("Contact Us")}</Button>
            </Span>
          </AltNavLinkSmall>
        </>
      );
    }
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logo.svg" width="120px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
