
const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/demo"],
    exact: true,
    component: "Demo",
  },
  {
    path: ["/pricing"],
    exact: true,
    component: "Pricing",
  },
  {
    path: ["/contact-us"],
    exact: true,
    component: "ContactUs",
  },
  {
    path: ["/checkout"],
    exact: true,
    component: "Checkout",
  },
  {
    path: ["/privacy-policy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/frequently-asked-questions"],
    exact: true,
    component: "FAQs",
  },
];

export default routes;
